import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import { isFieldForCategory, isFieldForListingType } from '../../../../util/fieldHelpers';
import { maxLength, required, composeValidators } from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  FieldSelect,
  FieldTextInput,
  Heading,
  CustomExtendedDataField,
  FieldCheckbox,
} from '../../../../components';
// Import modules from this directory
import css from './EditListingDetailsForm.module.css';
import appSettings from '../../../../config/settings';
import { ONBOARDING_TYPE_SERVICE } from '../../../../config/configCustomListing';
import { TEXT_TABS } from '../EditListingWizard';
import CustomStartEndJobField from './CustomStartEndJobField';
import GroupCategories from './GroupCategories/GroupCategories';
import { getTextId } from '../../../../util/dynamicTextId';
import CustomTimeFrameAvailableField from './CustomTimeFrameAvailableField';
import ChildcareDaySelector from './ChildcareDaySelector';

const TITLE_MAX_LENGTH = 40;
const DESCRIPTION_MAX_LENGTH = 50;
const isLocalhost = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  }
  return false;
};

const isDev = appSettings.dev && isLocalhost();
// Log the localhost status only once when the module is loaded
if (isLocalhost()) {
  console.log('Running on localhost');
  // Perform actions specific to localhost environment
}

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

const resetFieldMutator = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined);
};

// Field component that either allows selecting listing type (if multiple types are available)
// or just renders hidden fields:
// - listingType              Set of predefined configurations for each listing type
// - transactionProcessAlias  Initiate correct transaction against Marketplace API
// - unitType                 Main use case: pricing unit
const FieldSelectListingType = props => {
  const {
    name,
    listingTypes,
    hasExistingListingType,
    onListingTypeChange,
    formApi,
    formId,
    intl,
    onboardingType,
    selectableListingTypes,
  } = props;

  const hasMultipleListingTypes = listingTypes?.length > 1;

  const handleOnChange = value => {
    const selectedListingType = listingTypes.find(config => config.listingType === value);
    formApi.change('transactionProcessAlias', selectedListingType.transactionProcessAlias);
    formApi.change('unitType', selectedListingType.unitType);
    formApi.change('categoryLevel2', value);

    if (onListingTypeChange) {
      onListingTypeChange(selectedListingType);
    }
  };
  const getListingTypeLabel = listingType => {
    const listingTypeConfig = selectableListingTypes.find(
      config => config.listingType === listingType
    );
    return listingTypeConfig ? listingTypeConfig.label.split('--')[0].trim() : listingType;
  };
  let listingTypeLabelId = 'EditListingDetailsForm.listingTypeLabel';
  let listingTypePlaceholderId = 'EditListingDetailsForm.listingTypePlaceholder';
  let listingTypeRequiredId = 'EditListingDetailsForm.listingTypeRequired';
  if (onboardingType !== ONBOARDING_TYPE_SERVICE) {
    listingTypeLabelId = 'EditListingDetailsForm.listingTypeLabel_job';
    listingTypePlaceholderId = 'EditListingDetailsForm.listingTypePlaceholder_job';
    listingTypeRequiredId = 'EditListingDetailsForm.listingTypeRequired_job';
  }

  return hasMultipleListingTypes && !hasExistingListingType ? (
    <>
      <FieldSelect
        id={formId ? `${formId}.${name}` : name}
        name={name}
        className={css.listingTypeSelect}
        label={intl.formatMessage({ id: listingTypeLabelId })}
        validate={required(intl.formatMessage({ id: listingTypeRequiredId }))}
        onChange={handleOnChange}
      >
        <option disabled value="">
          {intl.formatMessage({ id: listingTypePlaceholderId })}
        </option>
        {listingTypes.map(config => {
          const type = config.listingType;
          const formatedLabel = config.label.split('--')[0].trim();
          return (
            <option key={type} value={type}>
              {formatedLabel}
            </option>
          );
        })}
      </FieldSelect>
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  ) : hasMultipleListingTypes && hasExistingListingType ? (
    <div className={css.listingTypeSelect}>
      <Heading as="h5" rootClassName={css.selectedLabel}>
        {intl.formatMessage({ id: listingTypeLabelId })}
      </Heading>
      <p className={css.selectedValue}>{getListingTypeLabel(formApi.getFieldState(name)?.value)}</p>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </div>
  ) : (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

// Finds the correct subcategory within the given categories array based on the provided categoryIdToFind.
const findCategoryConfig = (categories, categoryIdToFind) => {
  return categories?.find(category => category.id === categoryIdToFind);
};

/**
 * Recursively render subcategory field inputs if there are subcategories available.
 * This function calls itself with updated props to render nested category fields.
 * The select field is used for choosing a category or subcategory.
 */
const CategoryField = props => {
  const { currentCategoryOptions, level, values, prefix, handleCategoryChange, intl } = props;

  const currentCategoryKey = `${prefix}${level}`;

  const categoryConfig = findCategoryConfig(currentCategoryOptions, values[`${prefix}${level}`]);

  return (
    <>
      {currentCategoryOptions ? (
        <FieldSelect
          key={currentCategoryKey}
          id={currentCategoryKey}
          name={currentCategoryKey}
          className={css.listingTypeSelect}
          onChange={event => handleCategoryChange(event, level, currentCategoryOptions)}
          label={intl.formatMessage(
            { id: 'EditListingDetailsForm.categoryLabel' },
            { categoryLevel: currentCategoryKey }
          )}
          validate={required(
            intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryRequired' },
              { categoryLevel: currentCategoryKey }
            )
          )}
        >
          <option disabled value="">
            {intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryPlaceholder' },
              { categoryLevel: currentCategoryKey }
            )}
          </option>

          {currentCategoryOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FieldSelect>
      ) : null}

      {categoryConfig?.subcategories?.length > 0 ? (
        <CategoryField
          currentCategoryOptions={categoryConfig.subcategories}
          level={level + 1}
          values={values}
          prefix={prefix}
          handleCategoryChange={handleCategoryChange}
          intl={intl}
        />
      ) : null}
    </>
  );
};

const FieldSelectCategory = props => {
  useEffect(() => {
    checkIfInitialValuesExist();
  }, []);

  const { prefix, listingCategories, formApi, intl, setAllCategoriesChosen, values } = props;

  // Counts the number of selected categories in the form values based on the given prefix.
  const countSelectedCategories = () => {
    return Object.keys(values).filter(key => key.startsWith(prefix)).length;
  };

  // Checks if initial values exist for categories and sets the state accordingly.
  // If initial values exist, it sets `allCategoriesChosen` state to true; otherwise, it sets it to false
  const checkIfInitialValuesExist = () => {
    const count = countSelectedCategories(values, prefix);
    setAllCategoriesChosen(count > 0);
  };

  // If a parent category changes, clear all child category values
  const handleCategoryChange = (category, level, currentCategoryOptions) => {
    const selectedCatLenght = countSelectedCategories();
    if (level < selectedCatLenght) {
      for (let i = selectedCatLenght; i > level; i--) {
        formApi.change(`${prefix}${i}`, null);
      }
    }
    const categoryConfig = findCategoryConfig(currentCategoryOptions, category).subcategories;
    setAllCategoriesChosen(!categoryConfig || categoryConfig.length === 0);
  };

  return (
    <CategoryField
      currentCategoryOptions={listingCategories}
      level={1}
      values={values}
      prefix={prefix}
      handleCategoryChange={handleCategoryChange}
      intl={intl}
    />
  );
};

// Add collect data for listing fields (both publicData and privateData) based on configuration
const AddListingFields = props => {
  const {
    listingType,
    listingFieldsConfig,
    selectedCategories,
    formId,
    intl,
    values,
    formApi,
  } = props;
  const targetCategoryIds = Object.values(selectedCategories);
  useEffect(() => {
    if (!values.startDateJob) {
      formApi.change('startDateJob', {});
      formApi.change('endDateJob', {});
    }
  }, []);

  const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
    const { key, schemaType, scope } = fieldConfig || {};
    const namespacedKey = scope === 'public' ? `pub_${key}` : `priv_${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isProviderScope = ['public', 'private'].includes(scope);
    const isTargetListingType = isFieldForListingType(listingType, fieldConfig);
    const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

    const isFieldInCurrentTab =
      ![
        'onboardingType',
        'categoryGroup',
        'group_tutor_activities',
        'group_tutor_activities_job',
        'group_childcare',
        'group_childcare_job',
        'group_pregnancy_birth',
        'group_pregnancy_birth_job',
        'category',
        ...TEXT_TABS,
      ].includes(key) && !key.includes('jobFilters');
    const fieldRootClass = css[`custom_${schemaType}`];
    const fieldSpecificClass = css[`custom_${schemaType}_${key}`];
    const customClassName = classNames(fieldSpecificClass || fieldRootClass);
    const isCustomField = [
      'startEndDateJob',
      'durationMinMax',
      'timeFrameWeeks',
      'weekSchedule',
      'childcareDaysSelector',
    ].includes(key);

    return isKnownSchemaType &&
      isProviderScope &&
      isTargetListingType &&
      isTargetCategory &&
      isFieldInCurrentTab &&
      !isCustomField
      ? [
          ...pickedFields,
          <CustomExtendedDataField
            key={namespacedKey}
            name={namespacedKey}
            fieldConfig={fieldConfig}
            defaultRequiredMessage={intl.formatMessage({
              id: 'EditListingDetailsForm.defaultRequiredMessage',
            })}
            formId={formId}
            //custom
            className={customClassName}
          />,
        ]
      : isTargetListingType && isFieldInCurrentTab && key === 'startEndDateJob'
      ? [
          ...pickedFields,
          <CustomStartEndJobField key={key} values={values} intl={intl} form={formApi} />,
        ]
      : isTargetListingType && isFieldInCurrentTab && key === 'timeFrameWeeks'
      ? [
          ...pickedFields,
          <CustomTimeFrameAvailableField
            key={key}
            values={values}
            intl={intl}
            listingType={listingType}
            form={formApi}
          />,
        ]
      : isTargetListingType && isFieldInCurrentTab && key === 'childcareDaysSelector'
      ? [
          ...pickedFields,
          <ChildcareDaySelector
            key={key}
            values={values}
            intl={intl}
            listingType={listingType}
            form={formApi}
            OnUpdateAvailabilityExceptions={props.OnUpdateAvailabilityExceptions}
          />,
        ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

const findParentCategoryId = (categories, listingType) => {
  for (const category of categories) {
    for (const subcategory of category.subcategories) {
      if (subcategory.id === listingType) {
        return category.id;
      }
    }
  }
  return null; // If no match is found
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators, resetField: resetFieldMutator }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        selectableCategories,
        hasExistingListingType,
        pickSelectedCategories,
        categoryPrefix,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        publicData,
      } = formRenderProps;

      const {
        listingType,
        transactionProcessAlias,
        unitType,
        pub_onboardingType,
        categoryLevel1,
        categoryGroup,
      } = values;
      const [allCategoriesChosen, setAllCategoriesChosen] = useState(false);

      useEffect(() => {
        if (categoryGroup) {
          formApi.mutators.resetField('listingType');
        }
      }, [categoryLevel1, formApi.mutators]);
      useEffect(() => {
        if (!categoryLevel1 && listingType) {
          const parentCategoryId = findParentCategoryId(selectableCategories, listingType);
          formApi.change('categoryLevel1', parentCategoryId);
          formApi.change('categoryLevel2', listingType);
        }
      }, []);
      useEffect(() => {
        if (listingType === 'oogo_hotel' && !transactionProcessAlias) {
          formApi.change('transactionProcessAlias', 'default-inquiry/release-1');
          formApi.change('unitType', 'inquiry');
          formApi.change('categoryLevel2', listingType);
          onListingTypeChange(listingType);
        }
      }, []);

      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const maxLengtDescriptionMessage = maxLength(maxLengthMessage, DESCRIPTION_MAX_LENGTH);

      const validateCategoryGroup = value => {
        if (!value) {
          return 'A group category must be selected';
        }
        return undefined; // No error
      };

      const ShowListingType = !!categoryLevel1 || hasExistingListingType;

      const categoryGroupOptions =
        ShowListingType &&
        categoryLevel1 &&
        selectableCategories
          .find(obj => obj.id === categoryLevel1)
          ?.subcategories?.map(obj => obj.id)
          .filter(el => el !== 'childcare_package');

      const filteredListingTypes =
        (categoryGroupOptions &&
          selectableListingTypes.filter(item => categoryGroupOptions.includes(item.listingType))) ||
        selectableListingTypes;

      const hasListingType = !!listingType || filteredListingTypes?.length === 1;
      // Show title and description only after listing type is selected
      const showCategoryGroup =
        (!hasExistingListingType && !hasListingType) || !publicData?.listingType;
      const hasCategories = selectableCategories && selectableCategories.length > 0;
      const showCategories = listingType && hasCategories;

      const isUserHotel = props.userRole === 'user_hotel' || listingType === 'oogo_hotel';
      const showTitle = hasListingType;
      const showDescription = hasListingType && !isUserHotel;
      const showListingFields = hasListingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const hasMandatoryListingTypeData = listingType && transactionProcessAlias && unitType;
      const submitDisabled =
        invalid || disabled || submitInProgress || !hasMandatoryListingTypeData;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />
          {showCategoryGroup && (
            <GroupCategories
              form={formApi}
              validate={validateCategoryGroup}
              onboardingType={pub_onboardingType}
              listingType={listingType}
              onSetAllCategoriesChosen={setAllCategoriesChosen}
            />
          )}
          {ShowListingType && (
            <FieldSelectListingType
              name="listingType"
              listingTypes={filteredListingTypes}
              hasExistingListingType={hasExistingListingType}
              onListingTypeChange={onListingTypeChange}
              formApi={formApi}
              formId={formId}
              intl={intl}
              onboardingType={pub_onboardingType}
              autoFocus
              selectableListingTypes={selectableListingTypes}
            />
          )}
          {false ? (
            <FieldSelectCategory
              values={values}
              prefix={categoryPrefix}
              listingCategories={selectableCategories}
              formApi={formApi}
              intl={intl}
              allCategoriesChosen={allCategoriesChosen}
              setAllCategoriesChosen={setAllCategoriesChosen}
            />
          ) : null}

          {showTitle ? (
            <FieldTextInput
              id={`${formId}title`}
              name="title"
              className={css.title}
              type="text"
              label={intl.formatMessage({
                id: getTextId(`EditListingDetailsForm.title`, listingType, intl),
              })}
              placeholder={intl.formatMessage({
                id: getTextId('EditListingDetailsForm.titlePlaceholder', listingType, intl),
              })}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus={autoFocus}
            />
          ) : null}

          {showDescription ? (
            <FieldTextInput
              id={`${formId}description`}
              name="description"
              className={css.description}
              type="text"
              label={intl.formatMessage({
                id: getTextId('EditListingDetailsForm.description', listingType, intl),
              })}
              placeholder={intl.formatMessage({
                id: getTextId('EditListingDetailsForm.descriptionPlaceholder', listingType, intl),
              })}
              maxLength={DESCRIPTION_MAX_LENGTH}
              validate={composeValidators(
                required(
                  intl.formatMessage({
                    id: 'EditListingDetailsForm.descriptionRequired',
                  }),
                  maxLengtDescriptionMessage
                )
              )}
            />
          ) : null}

          {showListingFields ? (
            <AddListingFields
              listingType={listingType}
              listingFieldsConfig={listingFieldsConfig}
              selectedCategories={pickSelectedCategories(values)}
              formId={formId}
              intl={intl}
              values={values}
              formApi={formApi}
            />
          ) : null}

          {showDescription ? (
            <div className={css.spellCheckbox}>
              <FieldCheckbox
                id={`${formId}spellingCheck`}
                name="spellingCheck"
                label={intl.formatMessage({
                  id: 'EditListingDetailsForm.spelingCheck.label',
                })}
                value="true"
              />
            </div>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  pickSelectedCategories: func.isRequired,
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFields,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
