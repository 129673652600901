import React, { useEffect, useState } from 'react';
import { FieldDateInput, FieldSelect, Modal } from '../../../../components';
import { Field } from 'react-final-form';
import css from './ChildcareDaySelector.module.css';
import moment from 'moment';
import { object } from 'prop-types';

const ChildcareDaySelector = props => {
  const { intl, form, values } = props;

  const [days, setDays] = useState([]);
  const [startDateJob, setStartDateJob] = useState(null);
  const [endDateJob, setEndDateJob] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationError, setValidationError] = useState('');

  const displayFormat = 'MMMM D, YYYY';
  const TODAY = new Date();

  useEffect(() => {
    if (values.childcareDaySelector) {
      const loadedDays = values.childcareDaySelector.map(day => ({
        ...day,
        date: new Date(day.date),
      }));
      setDays(loadedDays);
      if (loadedDays.length > 0) {
        form.change('hasDays', true);
      }
    }
  }, [values.childcareDaySelector, form]);

  const updateFormWithDays = newDays => {
    const jsonDays = newDays.map(day => ({
      ...day,
      date: new Date(day.date).toJSON(),
    }));
    form.change('childcareDaySelector', jsonDays);
    form.change('hasDays', jsonDays.length > 0);
  };

  const handleAddDays = () => {
    if (startDateJob && endDateJob) {
      const daysMap = new Map();

      days.forEach(day => {
        const dateKey = moment(day.date).format('YYYY-MM-DD');
        const timeKey = `${day.startTime}-${day.endTime}`;
        const combinedKey = `${dateKey}-${timeKey}`;
        daysMap.set(combinedKey, day);
      });

      let currentDay = moment(startDateJob);
      const endDay = moment(endDateJob);

      while (currentDay.isSameOrBefore(endDay)) {
        const newStartTime = startTime || '00:00';
        const newEndTime = endTime || '23:59';
        const dateKey = currentDay.format('YYYY-MM-DD');
        const timeKey = `${newStartTime}-${newEndTime}`;
        const combinedKey = `${dateKey}-${timeKey}`;

        const day = {
          date: currentDay.clone().toDate(),
          startTime: newStartTime,
          endTime: newEndTime,
        };

        for (let [key, existingDay] of daysMap) {
          if (key.startsWith(dateKey)) {
            const existingStartTime =
              existingDay.startTime === 'No Time' ? '00:00' : existingDay.startTime;
            const existingEndTime =
              existingDay.endTime === 'No Time' ? '23:59' : existingDay.endTime;

            if (newStartTime <= existingEndTime && newEndTime >= existingStartTime) {
              daysMap.delete(key);
            }
          }
        }

        daysMap.set(combinedKey, day);
        currentDay.add(1, 'day');
      }

      const sortedDays = Array.from(daysMap.values()).sort((a, b) => {
        const dateComparison = moment(a.date).diff(moment(b.date));
        if (dateComparison !== 0) return dateComparison;

        const timeA = a.startTime === 'No Time' ? '00:00' : a.startTime;
        const timeB = b.startTime === 'No Time' ? '00:00' : b.startTime;
        return timeA.localeCompare(timeB);
      });

      setDays(sortedDays);
      updateFormWithDays(sortedDays);
      setIsModalOpen(false);
      setValidationError('');
      resetFormFields();
    }
  };

  const resetFormFields = () => {
    setStartDateJob(undefined);
    setEndDateJob(undefined);
    setStartTime('');
    setEndTime('');

    form.batch(() => {
      form.change('startDateJob', null);
      form.change('endDateJob', null);
      form.change('startTime', '');
      form.change('endTime', '');
    });
  };

  const handleRemoveDay = indexToRemove => {
    const updatedDays = days.filter((_, index) => index !== indexToRemove);
    setDays(updatedDays);
    updateFormWithDays(updatedDays);

    if (updatedDays.length === 0) {
      setValidationError('You need to add at least one day.');
      form.change('hasDays', false); // Set hasDays to false if no days are left
    }
  };

  const closeModal = () => {
    if (days.length === 0) {
      setValidationError('You need to add at least one day.');
      form.change('hasDays', false); // Ensure validation error triggers if no days are present
    }
    setIsModalOpen(false);
  };

  const openModal = () => {
    setValidationError('');
    setIsModalOpen(true);
  };

  const handleStartTimeChange = value => {
    const selectedStartTime = moment(value, 'HH:mm').format('HH:mm');
    setStartTime(selectedStartTime);
    form.change('startTime', selectedStartTime);

    const minEndTime = moment(selectedStartTime, 'HH:mm')
      .add(1, 'hour')
      .format('HH:mm');
    setEndTime(minEndTime);
    form.change('endTime', minEndTime);
  };

  const handleEndTimeChange = value => {
    setEndTime(value);
    form.change('endTime', value);
  };

  return (
    <div className={css.daySelectorContainer}>
      <div className={css.titleWrapper}>
        <label>Childcare Days</label>
        <button type="button" className={css.openModalButton} onClick={openModal}>
          Add Days
        </button>
      </div>

      {validationError && <div className={css.validationMessage}>{validationError}</div>}

      {/* Hidden field to ensure validation */}
      <Field
        name="hasDays"
        component="input"
        type="hidden"
        //  validate={value => (value ? undefined : 'You need to add days')}
      />

      <div className={css.selectedDaysWrapper}>
        {days.map((day, index) => (
          <div key={index} className={css.dayPill}>
            <span>
              {moment(day.date).format(displayFormat)}{' '}
              {(day.startTime && day.startTime !== 'No Time') ||
              (day.endTime && day.endTime !== 'No Time')
                ? `(${day.startTime} - ${day.endTime})`
                : ''}
            </span>
            <button
              type="button"
              className={css.removeButton}
              onClick={() => handleRemoveDay(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>

      <Modal
        id="childcareDaySelectorModal"
        isOpen={isModalOpen}
        onClose={closeModal}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <div className={css.modalContent}>
          <p>Select Dates and Times</p>
          <div className={css.dateSelectionWrapper}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="startDateJob"
              id="startDateJob"
              label="Start Date"
              placeholderText={intl.formatDate(TODAY, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
              useMobileMargins
              showErrorMessage={false}
              displayFormat={displayFormat}
              isOutsideRange={day => day.isBefore(TODAY, 'day')}
              onChange={date => {
                const selectedStartDate = date.date;

                if (endDateJob && moment(selectedStartDate).isAfter(endDateJob, 'day')) {
                  setEndDateJob(selectedStartDate);
                  form.change('endDateJob', selectedStartDate);
                }

                setStartDateJob(selectedStartDate);
              }}
            />
            <FieldDateInput
              className={css.fieldDateInput}
              name="endDateJob"
              id="endDatJob"
              label="End Date"
              placeholderText={intl.formatDate(TODAY, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
              useMobileMargins
              showErrorMessage={false}
              displayFormat={displayFormat}
              isOutsideRange={day => {
                const startDay = startDateJob ? moment(startDateJob).startOf('day') : TODAY;
                return day.isBefore(startDay, 'day');
              }}
              onChange={date => setEndDateJob(date.date)}
            />
          </div>

          <div className={css.timeSelectionWrapper}>
            <FieldSelect
              name="startTime"
              id="startTime"
              label="Start Time (Optional)"
              onChange={handleStartTimeChange}
              value={startTime || '00:00'}
              className={css.fieldSelect}
              disabled={!endDateJob}
            >
              <option value="00:00">00:00</option>
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={moment({ hour: i }).format('HH:mm')}>
                  {moment({ hour: i }).format('HH:mm')}
                </option>
              ))}
            </FieldSelect>
            <FieldSelect
              name="endTime"
              id="endTime"
              label="End Time (Optional)"
              onChange={handleEndTimeChange}
              value={endTime || '23:59'}
              className={css.fieldSelect}
              disabled={!startTime}
            >
              <option value="23:59">23:59</option>
              {Array.from({ length: 24 }, (_, i) => {
                const timeOption = moment({ hour: i }).format('HH:mm');

                if (
                  startTime &&
                  moment(timeOption, 'HH:mm').isSameOrAfter(
                    moment(startTime, 'HH:mm').add(1, 'hour')
                  )
                ) {
                  return (
                    <option key={i} value={timeOption}>
                      {timeOption}
                    </option>
                  );
                }
                return null;
              })}
            </FieldSelect>
          </div>

          <div className={css.modalActions}>
            <button
              type="button"
              className={css.addButton}
              onClick={handleAddDays}
              disabled={!startDateJob || !endDateJob}
            >
              Add Days
            </button>
            <button type="button" className={css.cancelButton} onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ChildcareDaySelector.defaultProps = {
  startDateJob: null,
  endDateJob: null,
};

ChildcareDaySelector.propTypes = {
  startDateJob: object,
  endDateJob: object,
};

export default ChildcareDaySelector;
