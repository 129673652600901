import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';

// Import configs and util modules
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../../util/urlHelpers';
import { ensureListing } from '../../../util/data';
import { createResourceLocatorString } from '../../../util/routes';
import { propTypes } from '../../../util/types';

// Import modules from this directory
import EditListingAvailabilityPanel from './EditListingAvailabilityPanel/EditListingAvailabilityPanel';
import EditListingDetailsPanel from './EditListingDetailsPanel/EditListingDetailsPanel';
import EditListingCustomTextPanel from './EditListingCustomTextPanel/EditListingCustomTextPanel';
import EditListingDeliveryPanel from './EditListingDeliveryPanel/EditListingDeliveryPanel';
import EditListingLocationPanel from './EditListingLocationPanel/EditListingLocationPanel';
import EditListingPhotosPanel from './EditListingPhotosPanel/EditListingPhotosPanel';
import EditListingPricingPanel from './EditListingPricingPanel/EditListingPricingPanel';
import EditListingPricingAndStockPanel from './EditListingPricingAndStockPanel/EditListingPricingAndStockPanel';
import EditListingPackagesPanel from './EditListingPackagesPanel/EditListingPackagesPanel';

import css from './EditListingWizardTab.module.css';
import EditListingJobFiltersPanel from './EditListingJobFiltersPanel/EditListingJobFiltersPanel';
import { TEXT_TABS } from './EditListingWizard';

/* custom */
import { CircularProgressbar } from 'react-circular-progressbar';
import EditListingJobSchedulePanel from './EditListingJobSchedulePanel/EditListingJobSchedulePanel';
import { USER_TYPE_MINDER } from '../../../config/configCustomUser';

export const DETAILS = 'details';
export const JOB_FILTERS = 'jobFilters';
export const DEAR_FAMILY = 'dearFamily';
export const FAV_MEMORY = 'favMemory';
export const PERSONALITY = 'personality';
export const MY_FAMILY = 'myFamily';
export const EDU_LANGUAGES = 'eduLanguages';
export const INTERESTS = 'interests';
export const MOTIVATION = 'motivation';
export const EXPERIENCE = 'experience';
export const EXPECTATION = 'expectation';
export const SKILLS_TALENTS = 'skillsTalents';
export const PHILOSOPHY_APPROACH = 'approachPhilosophy';
export const FACILITIES_AMENITIES = 'facilitiesAmenities';
export const STAFF_QUALIFICATIONS = 'staffQualifications';
export const SAFETY_SECURITY_MEASURES = 'safetySecurityMeasures';
export const NUTRITION_MEAL_PLANNING = 'nutritionMealPlans';
export const PARENT_COMMUNICATION_UPDATES = 'parentCommunicationUpdates';
export const INCLUSION_DIVERSITY = 'inclusionDiversity';

export const ABOUT_FAMILY = 'aboutFamily';

export const JOB_DESCRIPTION = 'jobDescription';
export const FAMILY_ROUTINE = 'familyRoutine';
export const HOME_ENVIRONMENT = 'homeEnvironment';
export const PARENTING_STYLE = 'parentingStyle';
export const FAMILY_EXPECTATIONS = 'familyExpectations';
export const HEALTH_NOTES = 'healthNotes';
export const QUALIFICATIONS_EXPERIENCE = 'qualificationsExperience';
export const ADDITIONAL_INFO = 'additionalInfo';
export const SERVICES_TECHNIQUE = 'servicesTechnique';
export const FAMILY_BUDGET = 'familyBudget';

export const PACKAGES = 'packages';
export const JOB_SCHEDULE = 'jobSchedule';

export const PRICING = 'pricing';
export const PRICING_AND_STOCK = 'pricing-and-stock';
export const DELIVERY = 'delivery';
export const LOCATION = 'location';
export const AVAILABILITY = 'availability';
export const PHOTOS = 'photos';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DETAILS,
  JOB_FILTERS,
  DEAR_FAMILY,
  FAV_MEMORY,
  PERSONALITY,
  MY_FAMILY,
  EDU_LANGUAGES,
  INTERESTS,
  MOTIVATION,
  EXPERIENCE,
  EXPECTATION,
  SKILLS_TALENTS,
  PHILOSOPHY_APPROACH,
  FACILITIES_AMENITIES,
  STAFF_QUALIFICATIONS,
  SAFETY_SECURITY_MEASURES,
  NUTRITION_MEAL_PLANNING,
  PARENT_COMMUNICATION_UPDATES,
  INCLUSION_DIVERSITY,

  ABOUT_FAMILY,
  JOB_DESCRIPTION,
  FAMILY_ROUTINE,
  HOME_ENVIRONMENT,
  PARENTING_STYLE,
  FAMILY_EXPECTATIONS,
  HEALTH_NOTES,
  QUALIFICATIONS_EXPERIENCE,
  ADDITIONAL_INFO,
  FAMILY_BUDGET,
  SERVICES_TECHNIQUE,

  PACKAGES,
  JOB_SCHEDULE,

  PRICING,
  PRICING_AND_STOCK,
  DELIVERY,
  LOCATION,
  AVAILABILITY,
  PHOTOS,
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history, routes) => {
  const listingUUID = listingId.uuid;
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingUUID,
  };

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    locationSearch,
    errors,
    fetchInProgress,
    newListingPublished,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    history,
    images,
    listing,
    weeklyExceptionQueries,
    monthlyExceptionQueries,
    allExceptions,
    onFetchExceptions,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onManageDisableScrolling,
    onListingTypeChange,
    onRemoveImage,
    updatedTab,
    updateInProgress,
    tabSubmitButtonText,
    config,
    routeConfiguration,
    //custom
    userProfile,
    userListingInfo,
    onCategoryTypeChange,
    onOnboardingTypeChange,
    updateProgressBar,
    listingProgress,
    intl,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);

  const listingProgressComponent =
    userListingInfo.currentUserRole === USER_TYPE_MINDER ? (
      <div className={css.wrapProgress}>
        <span className={css.progressText}>
          {props.intl.formatMessage({ id: 'TopbarDesktop.progressListing' })}
        </span>
        <div className={css.progressBar}>
          <CircularProgressbar
            value={listingProgress?.percentageCompleted || 0}
            text={`${listingProgress?.percentageCompleted || 0}%`}
            styles={{ text: { fontSize: '20px' } }}
          />
        </div>
      </div>
    ) : null;

  // New listing flow has automatic redirects to new tab on the wizard
  // and the last panel calls publishListing API endpoint.
  const automaticRedirectsForNewListingFlow = (tab, listingId) => {
    if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
      // Create listing flow: smooth scrolling polyfill to scroll to correct tab
      handleCreateFlowTabScrolling(false);

      // After successful saving of draft data, user should be redirected to next tab
      redirectAfterDraftUpdate(
        listingId,
        params,
        tab,
        marketplaceTabs,
        history,
        routeConfiguration
      );
    } else {
      handlePublishListing(listingId);
    }
  };

  const onCompleteEditListingWizardTab = (tab, updateValues) => {
    const onUpdateListingOrCreateListingDraft = isNewURI
      ? (tab, values) => onCreateListingDraft(values, config)
      : (tab, values) => onUpdateListing(tab, values, config);

    const updateListingValues = isNewURI
      ? updateValues
      : { ...updateValues, id: currentListing.id };

    return onUpdateListingOrCreateListingDraft(tab, updateListingValues)
      .then(r => {
        // In Availability tab, the submitted data (plan) is inside a modal
        // We don't redirect provider immediately after plan is set
        if (isNewListingFlow && tab !== AVAILABILITY) {
          const listingId = r.data.data.id;
          automaticRedirectsForNewListingFlow(tab, listingId);
        }
      })
      .catch(e => {
        // No need for extra actions
      });
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      panelUpdated: updatedTab === tab,
      params,
      locationSearch,
      updateInProgress,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      submitButtonText: tabSubmitButtonText,
      listingTypes: config.listing.listingTypes,
      onManageDisableScrolling,
      onSubmit: values => {
        // Initialize publicData if it doesn't exist
        if (!values.publicData) {
          values.publicData = {};
        }
        const onboardingProgress = updateProgressBar(values);

        values.publicData['progress'] = onboardingProgress;

        return onCompleteEditListingWizardTab(tab, values);
      },
      //custom
      ...userListingInfo,
      progressBarComponent: listingProgressComponent,
      containerTopPanelClassName: css.containerTopPanelClassName,
      currentTab: tab,
    };
  };

  // TODO: add missing cases for supported tabs
  switch (tab) {
    case DETAILS: {
      return (
        <EditListingDetailsPanel
          {...panelProps(DETAILS)}
          onListingTypeChange={onListingTypeChange}
          config={config}
          //custom
          userProfile={userProfile}
          onCategoryTypeChange={onCategoryTypeChange}
          onOnboardingTypeChange={onOnboardingTypeChange}
          intl={intl}
          onAddAvailabilityException={onAddAvailabilityException}
          allExceptions={allExceptions}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
        />
      );
    }
    case JOB_FILTERS: {
      return (
        <EditListingJobFiltersPanel
          {...panelProps(JOB_FILTERS)}
          onListingTypeChange={onListingTypeChange}
          config={config}
        />
      );
    }
    case PACKAGES: {
      return (
        <EditListingPackagesPanel
          {...panelProps(PACKAGES)}
          marketplaceCurrency={config.currency}
          listingMinimumPriceSubUnits={config.listingMinimumPriceSubUnits}
        />
      );
    }
    case PRICING_AND_STOCK: {
      return (
        <EditListingPricingAndStockPanel
          {...panelProps(PRICING_AND_STOCK)}
          marketplaceCurrency={config.currency}
          listingMinimumPriceSubUnits={config.listingMinimumPriceSubUnits}
        />
      );
    }
    case PRICING: {
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          marketplaceCurrency={config.currency}
          listingMinimumPriceSubUnits={config.listingMinimumPriceSubUnits}
          onSaveCurrency={values => {
            const updatedValues = {
              ...values,
              id: currentListing.id,
            };
            onUpdateListing(tab, updatedValues, config);
          }}
        />
      );
    }
    case DELIVERY: {
      return (
        <EditListingDeliveryPanel {...panelProps(DELIVERY)} marketplaceCurrency={config.currency} />
      );
    }
    case LOCATION: {
      return <EditListingLocationPanel {...panelProps(LOCATION)} />;
    }
    case AVAILABILITY: {
      return (
        <EditListingAvailabilityPanel
          allExceptions={allExceptions}
          weeklyExceptionQueries={weeklyExceptionQueries}
          monthlyExceptionQueries={monthlyExceptionQueries}
          onFetchExceptions={onFetchExceptions}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onNextTab={() =>
            redirectAfterDraftUpdate(
              listing.id,
              params,
              tab,
              marketplaceTabs,
              history,
              routeConfiguration
            )
          }
          config={config}
          history={history}
          routeConfiguration={routeConfiguration}
          {...panelProps(AVAILABILITY)}
        />
      );
    }
    case JOB_SCHEDULE: {
      return (
        <EditListingJobSchedulePanel
          allExceptions={allExceptions}
          weeklyExceptionQueries={weeklyExceptionQueries}
          monthlyExceptionQueries={monthlyExceptionQueries}
          onFetchExceptions={onFetchExceptions}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onNextTab={() =>
            redirectAfterDraftUpdate(
              listing.id,
              params,
              tab,
              marketplaceTabs,
              history,
              routeConfiguration
            )
          }
          config={config}
          history={history}
          routeConfiguration={routeConfiguration}
          {...panelProps(AVAILABILITY)}
        />
      );
    }
    case PHOTOS: {
      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          listingImageConfig={config.layout.listingImage}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
        />
      );
    }
    default:
      if (TEXT_TABS.includes(tab)) {
        return (
          <EditListingCustomTextPanel
            {...panelProps(tab)}
            tab={tab}
            onSaveTextbox={values => {
              const updatedValues = {
                ...values,
                id: currentListing.id,
              };
              onUpdateListing(tab, updatedValues, config);
            }}
            intl={intl}
            config={config}
          />
        );
      }
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  locationSearch: object,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onRemoveImage: func.isRequired,
  onListingTypeChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  config: object.isRequired,
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

export default EditListingWizardTab;
