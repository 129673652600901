import { getMonthRangesExcludingInterval } from './dates';
import moment from 'moment';

export async function updateAvailabilityExceptions({
  startDateJob,
  endDateJob,
  allExceptions,
  onAddAvailabilityException,
  onDeleteAvailabilityException,
  listing,
  availability = 'not-available',
}) {
  const excludedMonthRanges = getMonthRangesExcludingInterval(startDateJob, endDateJob);

  const exceptionMap = new Map(
    allExceptions.map(ex => [`${ex.attributes.start}-${ex.attributes.end}`, ex])
  );

  const toAdd = [];
  const toDelete = new Set();

  for (let { startDateTime, endDateTime } of excludedMonthRanges) {
    const key = `${startDateTime}-${endDateTime}`;
    if (!exceptionMap.has(key)) {
      toAdd.push({ startDateTime, endDateTime });
    } else {
      exceptionMap.delete(key);
    }
  }

  for (let ex of exceptionMap.values()) {
    toDelete.add({
      id: ex.id,
      start: ex.attributes.start,
      end: ex.attributes.end,
    });
  }

  const deletePromises = Array.from(toDelete).map(({ id, start, end }) =>
    onDeleteAvailabilityException({ id }).catch(error => {
      console.error(`Failed to delete availability exception for ${start} to ${end}:`, error);
    })
  );
  await Promise.all(deletePromises);

  /*   const availability = 'not-available'; */
  const seats = availability === 'available' ? 1 : 0;
  for (let { startDateTime, endDateTime } of toAdd) {
    const params = {
      listingId: listing.id,
      seats,
      start: startDateTime,
      end: endDateTime,
    };

    try {
      await onAddAvailabilityException(params);
    } catch (error) {
      console.error('Failed to add availability exception:', error);
    }
  }
}

const parseTime = timeStr => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return hours * 60 + minutes;
};

export const timeRangesPeriodOfDay = {
  morning: { start: parseTime('06:00'), end: parseTime('12:00') },
  afternoon: { start: parseTime('12:00'), end: parseTime('17:00') },
  evening: { start: parseTime('17:00'), end: parseTime('21:00') },
  night: { start: parseTime('21:00'), end: parseTime('24:00') },
  overnight: { start: parseTime('00:00'), end: parseTime('06:00') },
};

export function transformAvailability(entries) {
  const periods = {
    morning: { times: {} },
    afternoon: { times: {} },
    evening: { times: {} },
    night: { times: {} },
    overnight: { times: {} },
  };

  function formatTime(minutes) {
    const hours = Math.floor(minutes / 60) % 24;
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  const addEntryToPeriod = (entry, period, start, end) => {
    if (!periods[period].times[entry.dayOfWeek]) {
      periods[period].times[entry.dayOfWeek] = [];
    }

    const formattedStartTime = formatTime(start);
    const formattedEndTime = formatTime(end);

    periods[period].times[entry.dayOfWeek].push({
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      seats: entry.seats,
    });
  };
  entries.forEach(entry => {
    const startTime = parseTime(entry.startTime);
    const endTime = parseTime(entry.endTime === '00:00' ? '24:00' : entry.endTime);
    Object.keys(timeRangesPeriodOfDay).forEach(period => {
      const range = timeRangesPeriodOfDay[period];
      const startInPeriod = Math.max(range.start, startTime);
      const endInPeriod = Math.min(range.end, endTime);

      if (startInPeriod < endInPeriod) {
        addEntryToPeriod(entry, period, startInPeriod, endInPeriod);
      }
    });
  });
  Object.keys(periods.overnight.times).forEach(day => {
    if (!periods.night.times[day]) {
      periods.night.times[day] = [];
    }
    periods.night.times[day].push(...periods.overnight.times[day]);
  });

  delete periods.overnight;
  return Object.keys(periods).map(period => ({
    period: period,
    days: periods[period].times,
  }));
}

export function mergeTimeSlots(periods) {
  const mergedPeriods = [];

  const formatTime = minutes => {
    const hours = Math.floor(minutes / 60) % 24;
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  };

  const mergeSlots = slots => {
    if (!slots.length) return [];
    slots.sort((a, b) => parseTime(a.startTime) - parseTime(b.startTime));
    const merged = [slots[0]];

    for (let i = 1; i < slots.length; i++) {
      const last = merged[merged.length - 1];
      const current = slots[i];
      if (parseTime(current.startTime) <= parseTime(last.endTime)) {
        last.endTime =
          parseTime(current.endTime) > parseTime(last.endTime)
            ? formatTime(parseTime(current.endTime))
            : last.endTime;
      } else {
        merged.push(current);
      }
    }
    return merged;
  };
  periods.forEach(periodData => {
    const periodName = periodData.period;
    const daysData = periodData.days;

    const mergedDays = {};
    Object.keys(daysData).forEach(day => {
      mergedDays[day] = mergeSlots(daysData[day]);
    });

    mergedPeriods.push({ period: periodName, days: mergedDays });
  });

  return mergedPeriods;
}

export function findActivePeriods(periods) {
  const activePeriods = [];
  function mapDayKeyToFullName(dayKey) {
    const dayMap = {
      mon: 'monday',
      tue: 'tuesday',
      wed: 'wednesday',
      thu: 'thursday',
      fri: 'friday',
      sat: 'saturday',
      sun: 'sunday',
    };
    return dayMap[dayKey] || dayKey;
  }
  periods.forEach(periodOfDay => {
    Object.keys(periodOfDay.days).forEach(dayKey => {
      if (periodOfDay.days[dayKey].length > 0) {
        const dayName = mapDayKeyToFullName(dayKey);
        const periodDescriptor = `${dayName}_${periodOfDay.period}`;
        activePeriods.push(periodDescriptor);
      }
    });
  });
  return activePeriods;
}

// Helper to parse the date range into days of the week
export function parseDateRange(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const daysOfWeek = [];
  while (start <= end) {
    const dayName = start.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
    const dayMap = {
      monday: 'mon',
      tuesday: 'tue',
      wednesday: 'wed',
      thursday: 'thu',
      friday: 'fri',
      saturday: 'sat',
      sunday: 'sun',
    };
    daysOfWeek.push(dayMap[dayName]);
    start.setDate(start.getDate() + 1);
  }
  return daysOfWeek;
}
// Function to filter listings based on period and week availability
export function filterListingsByAvailability(listings, daysOfWeek, periods) {
  return listings.filter(listing => {
    return periods.every(period => {
      return daysOfWeek.some(dayKey => {
        return (
          listing.attributes.publicData.weekAvailability &&
          listing.attributes.publicData?.weekAvailability.some(availability => {
            return (
              availability.period === period &&
              availability.days[dayKey] &&
              availability.days[dayKey].length > 0
            );
          })
        );
      });
    });
  });
}

export async function updateAvailabilityWithConsecutiveRanges({
  days,
  allExceptions,
  onAddAvailabilityException,
  onDeleteAvailabilityException,
  listing,
}) {
  const sortedDays = days.sort((a, b) => new Date(a.date) - new Date(b.date));

  const consecutiveRanges = [];
  let startOfRange = null;
  let endOfRange = null;

  sortedDays.forEach((day, index) => {
    const startDateTime = moment(day.date)
      .set({
        hour: parseInt(day.startTime.split(':')[0], 10),
        minute: parseInt(day.startTime.split(':')[1], 10),
        second: 0,
        millisecond: 0,
      })
      .toDate();

    let endDateTime = moment(day.date)
      .set({
        hour: parseInt(day.endTime.split(':')[0], 10),
        minute: parseInt(day.endTime.split(':')[1], 10),
        second: 0,
        millisecond: 0,
      })
      .toDate();

    // Adjust if endTime is 23:59 to 00:00 of the next day
    if (day.endTime === '23:59') {
      endDateTime = moment(day.date)
        .add(1, 'day')
        .startOf('day')
        .toDate();
    }

    if (!startOfRange) {
      startOfRange = startDateTime;
      endOfRange = endDateTime;
    } else {
      const prevDay = sortedDays[index - 1];
      const prevDate = moment(prevDay.date)
        .add(1, 'day')
        .startOf('day');

      if (moment(day.date).isSame(prevDate, 'day')) {
        endOfRange = endDateTime;
      } else {
        consecutiveRanges.push({ startDateTime: startOfRange, endDateTime: endOfRange });
        startOfRange = startDateTime;
        endOfRange = endDateTime;
      }
    }

    if (index === sortedDays.length - 1) {
      consecutiveRanges.push({ startDateTime: startOfRange, endDateTime: endOfRange });
    }
  });

  const exceptionMap = new Map(
    allExceptions.map(ex => [`${ex.attributes.start}-${ex.attributes.end}`, ex])
  );

  const toAdd = [];
  const toDelete = new Set();

  for (let { startDateTime, endDateTime } of consecutiveRanges) {
    const key = `${startDateTime}-${endDateTime}`;
    if (!exceptionMap.has(key)) {
      toAdd.push({ startDateTime, endDateTime });
    } else {
      exceptionMap.delete(key);
    }
  }

  for (let ex of exceptionMap.values()) {
    toDelete.add({
      id: ex.id,
      start: ex.attributes.start,
      end: ex.attributes.end,
    });
  }

  const deletePromises = Array.from(toDelete).map(({ id, start, end }) =>
    onDeleteAvailabilityException({ id }).catch(error => {
      console.error(`Failed to delete availability exception for ${start} to ${end}:`, error);
    })
  );
  await Promise.all(deletePromises);

  for (let { startDateTime, endDateTime } of toAdd) {
    const params = {
      listingId: listing.id,
      seats: 1, // or whatever logic you have for seats
      start: startDateTime,
      end: endDateTime,
    };

    try {
      await onAddAvailabilityException(params);
    } catch (error) {
      console.error('Failed to add availability exception:', error);
    }
  }
}
